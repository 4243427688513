var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark","variant":"info"}},[_c('b-navbar-brand',[_vm._v("Admin")]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',[_c('router-link',{class:[
              _vm.currentRoute.includes('/project-admin/dashboard')
                ? 'link-active'
                : 'link-normal' ],attrs:{"to":"/project-admin/dashboard"}},[_vm._v("Dashboard")])],1),_c('b-nav-item',[_c('router-link',{class:[
              _vm.currentRoute.includes('project-admin/users')
                ? 'link-active'
                : 'link-normal' ],attrs:{"to":"/project-admin/users"}},[_vm._v("Project Users")])],1),_c('b-nav-item',[_c('router-link',{class:[
              _vm.currentRoute.includes('project-admin/allusers')
                ? 'link-active'
                : 'link-normal' ],attrs:{"to":"/project-admin/allusers"}},[_vm._v("All Users")])],1),_c('b-nav-item',[_c('b-icon',{staticClass:"link-normal",attrs:{"icon":"power","aria-hidden":"true"}}),_c('a',{staticClass:"link-normal",on:{"click":_vm.handleLogout}},[_vm._v("Logout")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }